import React from 'react';
import {Col} from 'react-bootstrap';
import axios from 'axios';
const config = require('../config.json');

class ContactForm extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      successMsg: '',
      name: '',
      email: '',
      partySize: '1-5',
      message: ''
    }
  }


  handleSubmit = (event) => {
    event.preventDefault();
    console.log(this.state);

    if(this.state.name===''){
      this.setState({ successMsg: 'Name Required'})
    }else if(this.state.email===''){
      this.setState({successMsg:'Email Required'})
    }else if(this.state.message===''){
      this.setState({successMsg:'Message Required'})
    }else{
      axios.post(`${config.api.formURL}`, this.state);
      this.setState({
        successMsg: 'Message Sent',
        name: '',
        email: '',
        message: ''
      })
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  render(){
    let BtnStyle = {
      backgroundColor: 'red',
      color: 'white',
      borderStyle: 'none'
    }
    return(
      <>
      <form style={{fontFamily: "Arial"}} onSubmit={this.handleSubmit}>
        <input type="text" name="name" value={this.state.name} onChange={this.handleInputChange} placeholder="Name"/>
        <br/>
        <input type="email" name="email" value={this.state.email} onChange={this.handleInputChange} placeholder="Email"/>
        <br/>
        <span>Party Size:  </span>
        <select onChange={this.handleInputChange}>
          <option value="1-5">1-5</option>
          <option value="5-10">5-10</option>
          <option value="10-20">10-20</option>
          <option value="20+">20+</option>
        </select>
        <Col>
          <textarea className="form-control" id="ContactMessage" name="message" rows="5" value={this.state.message} onChange={this.handleInputChange} placeholder="Message"></textarea>
        </Col>
        <br/>
        <button style={BtnStyle} type="button" onClick={this.handleSubmit}>Submit</button>
      </form>
      <span styles={{color:'red'}}>{this.state.successMsg}</span>
      <br/>
     </>
    )
  }

}

export default ContactForm;
