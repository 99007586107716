import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import {Row, Col, Container} from 'react-bootstrap';

function FooterComponent(){
  return(
    <footer className="footer">
    <Container>
      <Row>
        <Col>
          <strong style={{color:'rgb(245, 186, 108)'}}>Hours</strong>
          <br/>
          <div style={{fontSize: '12px'}}>
            <strong>Sunday - Thursay:	</strong> 2PM–2AM
            <br/>
            <strong>Friday & Saturday:	</strong>2PM–4:30AM
          </div>
        </Col>
        <Col>
          <div>
            <strong style={{color:'rgb(245, 186, 108)'}}>Contact</strong>
            <br/>
            <div style={{fontSize: '12px'}}>
            <strong>Phone: </strong>(512) 203-2419
            <br/>
            <strong>Email: </strong>AmneziaHookahLounge@gmail.com
            </div>
          </div>
        </Col>
        <Col>
          <div>
          <strong style={{color:'rgb(245, 186, 108)'}}>Follow Us</strong>
          </div>
          <a href="https://www.facebook.com/amneziahookahlounge/">
            <FontAwesomeIcon icon={faFacebook} />{"   "}
          </a>
          <a href="https://www.instagram.com/amnezia15">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </Col>
      </Row>
      <br/>
      <div className="footer-copyright text-center" >© 2021 Copyright: <strong style={{color:'rgb(245, 186, 108)'}}>Amnezia Hookah Lounge & Smoke Shop</strong></div>
      </Container>
    </footer>
  )
}

export default FooterComponent;
