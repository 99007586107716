import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import {Helmet} from "react-helmet";

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Home from './pages/Home';
import Menu from './pages/Menu';
import Reservation from './pages/Reservation';
import Gallery from './pages/Gallery';
import Events from './pages/Event';

function App() {
  return (
    <div className="App">
      <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content="Amnezia Best Hookah Lounge Cafe and Events in Austin Pflugerville"/>
          <meta name="keywords" content="Amnezia, Hookah, Lounge, Smoke, Shop, Cafe, Austin, Pflugerville"/>
          <title>Amnezia Hookah Lounge Cafe and Smoke Shop</title>
          <link rel="canonical" href="https://amneziahookah.netlify.com/"/>
      </Helmet>
      <Router>
        <Switch>
          <Route path="/events" component={Events} />
          <Route path="/Gallery" component={Gallery} />
          <Route path="/reservation" component={Reservation} />
          <Route path="/menu" component={Menu} />
          <Route path="/" component={Home} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
