import React from 'react';

function FixedImageSection(){
  const pStyle = {
    color:'white',
    fontFamily: 'Kaushan+Script',
    textShadow: '2px 2px black',
  }

  return(
    <section className = 'fixedSection'>
      <h1>Open Daily</h1>
      <h5>
      <div style={pStyle}>
        <br/>
        <strong>Sunday - Thursay:  </strong><strong style={{color:'rgb(245, 186, 108)'}}>2PM–2AM</strong>
        <br/>
        <strong>Friday & Saturday: </strong><strong style={{color:'rgb(245, 186, 108)'}}>2PM–5AM</strong>
        <br/>
        <strong>Happy Hour Monday - Thursday: </strong><strong style={{color:'rgb(245, 186, 108)'}}>2PM–6PM</strong>
      </div>
      </h5>
      <h3 style={{marginTop: '420px', bottom: '0px', fontFamily: 'Cinzel', color: 'white'}}>We would love to have you whether you're coming to us to get work done, socialize or get your daily dose.</h3>
    </section>
  )
}

export default FixedImageSection;
