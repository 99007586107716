import React from 'react';

import Navbar from '../components/Navbar.js'
import Jumbotron from '../components/Jumbotron.js'
import Card from '../components/Card.js';
import FixedImageSection from '../components/FixedImgBGSection.js';
import SmokeShopImgSection from '../components/SmokeShopImgSection.js';
import Map from '../components/Map.js';
import Footer from '../components/Footer.js';
import {Image} from 'react-bootstrap';
import image1 from '../images/HappyHour.jpg'
import image2 from '../images/BYOB.jpg'
import image3 from '../images/visitus.jpg'

function Home() {
  return (
    <div className="Home">
      <Navbar />
      <Jumbotron/>
      <FixedImageSection/>
      <SmokeShopImgSection/>
      <Image style={{width:"100%"}} src={image2}/>
      <Image style={{width:"100%"}} src={image3}/>
      <Map address="1505 Demaret St, Pflugerville, TX 78660"/>
      <Footer />
    </div>
  );
}

export default Home;
