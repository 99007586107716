import React from 'react';
import {Jumbotron, Container} from 'react-bootstrap';
import bgimage from '../images/homebanner.jpg';

import styled, {keyframes} from 'styled-components';
import {bounceInRight} from 'react-animations';

const BounceRightIn = styled.div`animation: 2s ${keyframes`${bounceInRight}`}`;

function JumbotronComponent() {
  let wSize = window.innerWidth;
  console.log(wSize);
  let size;
  if(wSize<350){
    size = 550
  }else{
    size=wSize
  }
  const titleStyle = {
    fontSize: size/11
  };
  const tagStyle={
    fontSize:size/25
  }
  const divStyle = {
    paddingTop: '150px',
    backgroundImage: `url(${bgimage})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '90vh',
  };
  
  const pStyle = {
    color:'white',
    fontFamily: 'Kaushan+Script',
    textShadow: '2px 2px black',
  }

  return(
    <Jumbotron style={divStyle}>
      <Container>
        <h1 style = {titleStyle}>Amnezia Hookah Lounge and Smoke Shop</h1>
        <p style={pStyle}>
          <h4>
            <br/>
            <strong>The Best<br/> Hookah Lounge <br/> in Pflugerville</strong>
          </h4>
        </p>
      </Container>
    </Jumbotron>
  )
}

export default JumbotronComponent;
