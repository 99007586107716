import React from 'react';
import Navbar from '../components/Navbar.js'
import {Jumbotron, Container} from 'react-bootstrap';

import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer.js';

import nightview from '../images/events.jpg';

function Reservation(){
  const divStyle = {
    paddingTop: '50px',
    backgroundImage: `url(${nightview})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '50vh'
  };
  const pStyle = {
    color:'white',
    fontFamily: 'Cinzel',
    textShadow: '2px 2px 4px black',
  }

  return(
    <div className = "Contact">
      <Navbar/>
      <Jumbotron style={divStyle}>
      <Container>
        <br/>
        <h1>Book a Reservation</h1>
        <div style={pStyle}>
          <h4>
            <strong>Schedule Your Next Party Here Today</strong>
          </h4>
        </div>
      </Container>
      </Jumbotron>
      <ContactForm/>
      <br/>
      <Footer />
    </div>
  )
}

export default Reservation;
