import React from 'react';
import Navbar from '../components/Navbar.js'
import Footer from '../components/Footer.js'
import {Image} from 'react-bootstrap';
import image1 from '../images/saturdayflyer.jpg'
import image2 from '../images/saturdaybanner.jpg'
import image3 from '../images/fridayflyer.jpg'

function Events(){
  return(
    <div style={{backgroundImage:'linear-gradient(white, orange, rgb(230, 0, 0)'}}>
      <Navbar/>
        <h2 style={{color:' #e67300',fontFamily:'Courgette'}}>Friday and Saturday Night Events</h2>
        <Image src={image3} thumbnail />
        <Image src={image2} thumbnail />
        <Image src={image1} thumbnail />
        <br/>
      <Footer/>
    </div>
  )
}

export default Events;
