import React from 'react';
import {Nav, NavLink} from 'react-router-dom';

function NavbarComponent(){
  return(
    <nav style={{position:"sticky"}} className="navbar" role="navigation" aria-label="main navigation" >
      <div className="navbar-brand">
        <NavLink to="/" className="navbar-item">
          <img src="Logo.png" width="50" height="50" alt="Logo"/>
        </NavLink>
        <NavLink exact={true} to="/" activeClassName='is-active' className="navbar-item">
        <h2 style={{fontFamily: 'Italianno'}}><strong>Amnezia Hookah Lounge & Smoke Shop</strong></h2>
        </NavLink>
      </div>

      <div style={{fontSize: '8px'}} className="navbar-start">
        <NavLink to="/menu" activeClassName='is-active' className="navbar-item">
          <h5><strong>Menu</strong></h5>
        </NavLink>
        <NavLink to="/reservation" activeClassName='is-active' className="navbar-item">
          <h5><strong>Reservation</strong></h5>
        </NavLink>
        <NavLink to="/events" activeClassName='is-active' className="navbar-item">
          <h5><strong>Events</strong></h5>
        </NavLink>
        <NavLink to="/Gallery" activeClassName='is-active' className="navbar-item">
          <h5><strong>Gallery</strong></h5>
        </NavLink>
      </div>
    </nav>
  )
}

export default NavbarComponent;
