import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Gallery from 'react-photo-gallery';

import image1 from '../images/gallery/1.jpg'
import image1a from '../images/gallery/1a.jpg'
import image2 from '../images/gallery/2.jpg'
import image3 from '../images/gallery/3.jpg'
import image4 from '../images/gallery/4.jpg'
import image5 from '../images/gallery/5.jpg'
import image7 from '../images/gallery/7.jpg'
import image8 from '../images/gallery/8.jpg'
import image9 from '../images/gallery/9.jpg'
import image10 from '../images/gallery/10.jpg'
import image10a from '../images/gallery/10a.jpg'
import image11 from '../images/gallery/11.jpg'
import image12 from '../images/gallery/12.jpg'
import image13 from '../images/gallery/13.jpg'
import image15 from '../images/gallery/15.jpg'
import image16 from '../images/gallery/16.jpg'
import image17 from '../images/gallery/17.jpg'
import image18 from '../images/gallery/18.jpg'
import image19 from '../images/gallery/19.jpg'
import image20 from '../images/gallery/20.jpg'
import image21 from '../images/gallery/21.jpg'
import image22 from '../images/gallery/22.jpg'
import image23 from '../images/gallery/23.jpg'
import image24 from '../images/gallery/24.jpg'
import image25 from '../images/gallery/25.jpg'
import image26 from '../images/gallery/26.jpg'
import image27 from '../images/gallery/27.jpg'
import image28 from '../images/gallery/28.jpg'
import image29 from '../images/gallery/29.jpg'
import image30 from '../images/gallery/30.jpg'
import shop1 from '../images/smokeshop/shop1.jpg';
import shop2 from '../images/smokeshop/shop2.jpg';
import shop3 from '../images/smokeshop/shop3.jpg';
import shop4 from '../images/smokeshop/shop4.jpg';
import shop5 from '../images/smokeshop/shop5.jpg';
import shop6 from '../images/smokeshop/shop6.jpg';
import shop7 from '../images/smokeshop/shop7.jpg';
import shop8 from '../images/smokeshop/shop8.jpg';
import shop9 from '../images/smokeshop/shop9.jpg';
import shop10 from '../images/smokeshop/shop10.jpg';


let images = [image1,image1a,image2,image3,image4,image5,image7,image8,image9,image10,image10a,image11,image12,image13,image15,image16,image17,image18,image19,image20,image21,image22,image23,image24,image25,image26,image27,image28,image29,image30];
let shopImages = [
  shop1,
shop2,
shop3,
shop4,
shop5,
shop6,
shop7,
shop8,
shop9,
shop10,
]

const photos = [
  {
    src: image1,
    width: 3,
    height: 2
  },

  {
    src: image2,
    width: 4,
    height: 3
  },
  {
    src: image4,
    width: 4,
    height: 3
  },
  {
    src: image5,
    width: 4,
    height: 3
  },
  {
    src: image7,
    width: 4,
    height: 3
  },
  {
    src: image8,
    width: 4,
    height: 3
  },
  {
    src: image10,
    width: 4,
    height: 3
  },
  {
    src: image10a,
    width: 3,
    height: 4
  },
  {
    src: image11,
    width: 4,
    height: 3
  },
  {
    src: image20,
    width: 2,
    height: 1
  },
  {
    src: image12,
    width: 3,
    height: 4
  },
  {
    src: image13,
    width: 4,
    height: 3
  },
  {
    src: image15,
    width: 1,
    height: 2
  },
  {
    src: image16,
    width: 1,
    height: 2
  },
  {
    src: image18,
    width: 2,
    height: 3
  },
  {
    src: image17,
    width: 1,
    height: 2
  },
  {
    src: image21,
    width: 2,
    height: 3
  },
  {
    src: image19,
    width: 2,
    height: 3
  },

  {
    src: image22,
    width: 2,
    height: 3
  },
  {
    src: image23,
    width: 1,
    height: 2
  },
  {
    src: image24,
    width: 2,
    height: 3
  },
  {
    src: image1a,
    width: 2,
    height: 3
  },
  {
    src: image25,
    width: 2,
    height: 3
  },
  {
    src: image26,
    width: 2,
    height: 3
  },
  {
    src: image27,
    width: 4,
    height: 3
  },
  {
    src: image28,
    width: 4,
    height: 3
  },
  {
    src: image29,
    width: 4,
    height: 3
  },
  {
    src: image30,
    width: 4,
    height: 3
  },
];

const SmokeShopPhotos = []

function CarouselComponent() {
  let style = {
    fontFamily: 'Cinzel',
    maxHeight: '500px',
  }


  React.useEffect(()=>{
    shopImages.forEach(img=>
      SmokeShopPhotos.push(
        {
          src: img,
          width: 4,
          height: 3,
        }
      ))
  },[])

  return (
    <div styles={style}>
      <Gallery photos={photos} />
      <Gallery photos={SmokeShopPhotos} />
    </div>
  );
}

export default CarouselComponent;
