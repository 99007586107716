import React from 'react';

function SmokeShopImgSection(){
  const pStyle = {
    color:'white',
    fontFamily: 'Kaushan+Script',
    textShadow: '2px 2px black',
  }

  return(
    <section className = 'smokeshopsection'>
      <h1>Smoke Shop</h1>
      <h5>
      <div style={pStyle}>
        <br/>
        <strong>Buy your water pipes, CBD products, and other smoking accessories here </strong>
        <br/>
      </div>
      </h5>
    </section>
  )
}

export default SmokeShopImgSection;
