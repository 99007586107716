import React from 'react';
import Navbar from '../components/Navbar.js'
import Footer from '../components/Footer.js'
import Carousel from '../components/Carousel.js';

function Gallery(){
  return(
    <>
      <Navbar/>
        <Carousel/>
      <Footer/>
    </>
  )
}

export default Gallery;
