import React from 'react';
import Navbar from '../components/Navbar.js'
import Footer from '../components/Footer.js'
import {Row,Col,Image} from 'react-bootstrap';
import image1 from '../images/hookah.jpg'

function Menu(){
  const divStyle = {
    paddingTop: '50px',
    backgroundImage: `url(${image1})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '100vh'
  };

  return (
    <>
    <Navbar />
    <div className="Menu">
      <br/>
      <h1>Menu</h1>
      <p>Happy Hour Monday through Thursday from 2PM to 6PM  - $10.99 Hookahs</p>
      <p>Free BYOB Monday through Wednesday</p>
      <Row>
      </Row>
        <div style={{height: '1px', backgroundColor: 'white'}}></div>
        <br/>
      <Row>
        <Col>
          <h2>Hookah Flavors</h2>
          <h3>Starbuzz</h3>
          <p>Blue Mist, Strawberry Margarita, Pina Colada, Passionl Kiss, Tequila Sunrise, Mojito Classic, Sex on the Beach, Code 69</p>
          <h3>Al Fakher</h3>
          <p>Double Apple, Orange Mint, Orange Cream, Strawberry, Melon, Peach, Watermelon, Gum Mint, Berry, Bubble Gum, Mint, Pineapple, Lemon Mint, Grapefruits, Kiwi, Coconut, Jasmine, Grenadine, Guava, Rose, Cinamon, Cherry, Blueberry, Banana, Aprecot, Spearmint, Mongo</p>
          <br/>
          <Image src={image1} thumbnail />
        </Col>

        <Col>
        <h2>Hookah Bowls</h2>
        <p>
        Regular - $15.99
        <br/>
        <h3>Fruit Bowls</h3>
        Orange or Apple - $19.99
        <br/>
        Pineapple - $22.99
        <br/>
        Watermelon - $28.99
        <br/>
        </p>
        <br/>
        <div style={{height: '1px', backgroundColor: 'white'}}></div>
        <br/>

        <h2>Drinks</h2>
        <h3>Soft Drinks</h3>
          <p>
            Coke, Fanta, Sprite, Bottle of Water - $1.99
            <br/>
            Mexican Soda, Gatorade - $2.99
            <br/>
            Monster, Red Bull - $3.99
            <br/>
          </p>
        <h3>Hot Drinks</h3>
          <p>
          Moroccan Mint Tea S-$4.99, M-$6.99, L-$9.99
          <br/>
          Coffee Lattee S-$4.99, L-$6.99
          <br/>
          Coffe Espresso, Coffee Americano - $4.99
          <br/>
          Hot Chocolate - $4.99
          </p>
        <h3>Smoothies & Shakes</h3>
          <p>
          Amnezia Tropial Smoothie, Zaazaa - $9.99
          <br/>
          Avocado Almond, Strawberry Banana, Apple, Berry, Kiwi - $7.99
          </p>
        <br/>
        <h3>BYOB</h3>
          <p>
          $5 Thursday through Sunday
          </p>
        </Col>
      </Row>
      <br/>
      <div style={{height: '1px', backgroundColor: 'white'}}></div>
    </div>
    <Footer />
    </>
  );
}

export default Menu;
